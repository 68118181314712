.element-usps {
  i {
    color: $primaire-kleur;
    @include rem(font-size, 24px);
  }
  .element-usps--title {
    font-weight: 700;
    font-family: $font-family-headings;
    @include rem(font-size, 24px);

    @include media-breakpoint-down(md){
      border-bottom: 1px solid $gray-400;
      @include rem(margin, 10px 0!important);
    }
  }
}
