* {
  box-sizing: border-box;
}

html {
  background-color: $wit;
}

body {
  font-family: $font-family-body;
  color:$grijs-text;
  font-weight: 300;
  height: 100%;
}

strong {
  font-weight: 700;
}

img {
  max-width: 100%;
}

.message {
  @extend .alert;
  &.good {
    @extend .alert-success;
  }
}

.mobile-header-list {
  @include media-breakpoint-down(md){
    @include rem(min-width, 180px);
  }
}

.btn {
    z-index: 25;
    position: relative;
}

.nav-image{
  position: relative;
  overflow: hidden;
  border-radius: 6px;

  &::before{
    content:'';
    background: linear-gradient(to right, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 5;
  }
}

.collapsing {
  -webkit-transition: height 0.3s ease;
  -moz-transition: height 0.3s ease;
  -o-transition: height 0.3s ease;
  transition: height 0.3s ease;
}

.action {
  cursor: pointer;
}

.btn{
  font-family: $font-family-headings;
  @include rem(letter-spacing, .55px);
  @include rem(border-radius, 60px);
}

.nav-cart-icon {
  .badge {
    position: absolute;
  }
}

.dropdown {
  select {
    background: $white;
  }
}

select {
  &.dropdown {
    background: $white;
  }
}

.default-container{
  @include rem(padding, 0px 150px);
  @include media-breakpoint-between(md, xl) {
    @include rem(padding, 0px 75px);
  }
  @include media-breakpoint-between(sm, md) {
    @include rem(padding, 0px 50px);
  }
  @include media-breakpoint-between(xs, sm) {
    @include rem(padding, 0px 50px);
  }
  @include media-breakpoint-down(sm) {
    @include rem(padding, 0px 20px);
  }
}

.element-padded-big {
  @include rem(margin-top, 60px);
  @include rem(margin-bottom, 60px);

  @include rem(padding-top, 60px);
  @include rem(padding-bottom, 60px);

  @include media-breakpoint-between(md, xl) {
    @include rem(margin-top, 45px);
    @include rem(margin-bottom, 45px);

    @include rem(padding-top, 45px);
    @include rem(padding-bottom, 45px);
  }
  @include media-breakpoint-between(sm, md) {
    @include rem(margin-top, 45px);
    @include rem(margin-bottom, 45px);

    @include rem(padding-top, 45px);
    @include rem(padding-bottom, 45px);
  }
  @include media-breakpoint-between(xs, sm) {
    @include rem(padding-top, 30px);
    @include rem(padding-bottom, 30px);

    @include rem(margin-top, 30px);
    @include rem(margin-bottom, 30px);
  }
  @include media-breakpoint-down(sm) {
    @include rem(padding-top, 30px);
    @include rem(padding-bottom, 30px);

    @include rem(margin-top, 30px);
    @include rem(margin-bottom, 30px);
  }
}

.element-padded-medium {
  @include rem(margin-top, 45px);
  @include rem(margin-bottom, 45px);

  @include rem(padding-top, 45px);
  @include rem(padding-bottom, 45px);

  &.element-bg-image,
  .element-bg{
    @include rem(margin-top, 0);
    @include rem(margin-bottom, 0);

    @include rem(padding-top, 60px);
    @include rem(padding-bottom, 60px);
  }

  @include media-breakpoint-down(sm) {
    @include rem(padding-top, 30px);
    @include rem(padding-bottom, 30px);

    @include rem(margin-top, 30px);
    @include rem(margin-bottom, 30px);

    &.element-bg-image,
    .element-bg{
      @include rem(margin-top, 0);
      @include rem(margin-bottom, 0);

      @include rem(padding-top, 60px);
      @include rem(padding-bottom, 60px);
    }
  }
}

.element-padded {
  @include rem(margin-top, 30px);
  @include rem(margin-bottom, 30px);

  @include rem(padding-top, 30px);
  @include rem(padding-bottom, 30px);

  &.element-bg-image,
  .element-bg{
    @include rem(margin-top, 0);
    @include rem(margin-bottom, 0);

    @include rem(padding-top, 60px);
    @include rem(padding-bottom, 60px);
  }

  //@include media-breakpoint-down(sm) {
  //  @include rem(padding-top, 30px);
  //  @include rem(padding-bottom, 30px);
  //}
}

.banner-slide-prev,
.slide-prev,
.product-slide-prev{
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 30;
  left: 0;
  @include rem(padding, 15px);
  @include rem(font-size, 21px);
  border-radius: 6px;
  border: 0;
  background: $secundaire-kleur;
  color: $white;
}

.banner-slide-next,
.slide-next,
.product-slide-next{
  position: absolute;
  top: 45%;
  transform: translateY(-50%);
  z-index: 30;
  right: 0;
  @include rem(padding, 15px);
  @include rem(font-size, 21px);
  border-radius: 6px;
  border: 0;
  background: $secundaire-kleur;
  color: $white;
}

.product-slide-left{
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 30;
  right: 0;
  @include rem(padding, 15px);
  @include rem(font-size, 21px);
  border-radius: 6px;
  border: 0;
  background: $secundaire-kleur;
  color: $white;
}

.element-bg {
  background-color: transparentize($beige, .75);
}

.element-bg-image{
  background-position: top center;
  background-repeat: repeat-x;
}

.image-wrap{
  img {
    position: relative;
    overflow: hidden;
    display: block;
    border-radius: 6px;
  }
}

.with-leaf {
  position: relative;
  display: block;
  z-index: 20;

  img {
    position: relative;
    z-index: 20;
  }

  &::after{
    content:"";
    display: block;
    position: absolute;
    background-size: cover;
    bottom: 0;
    background-repeat: no-repeat;
    z-index: 0;
    opacity: 0.1;
    @include rem(width, 420px);
    @include rem(height, 420px);
    @include media-breakpoint-down(xxl) {
      @include rem(width, 380px);
      @include rem(height, 380px);
    }
    @include media-breakpoint-down(xl) {
      @include rem(width, 320px);
      @include rem(height, 320px);
    }
    @include media-breakpoint-down(lg) {
      @include rem(width, 240px);
      @include rem(height, 240px);
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.with-leaf-right {
  &::after{
    background-image:url('../img/blad_siersel_links.svg');
    transform: translateX(80%);
    right: 0;
  }
}
.with-leaf-left {
  &::after{
    background-image:url('../img/blad_siersel_rechts.svg');
    transform: translateX(-80%);
    left: 0;
  }
}

.with-shadow{
  position: relative;
  display: block;
  z-index: 20;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.10);

  img {
    position: relative;
    z-index: 20;
  }

  &::before{
    content: "";
    position: absolute;
    z-index: 0;
    box-shadow: 0 0 80px rgba(0, 0, 0, 0.5);
    bottom: 0px;
    width: 80%;
    height: 50%;
    -moz-border-radius: 100%;
    border-radius: 30%;
    left: 10%;
    right: 10%;
  }

}
