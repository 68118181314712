.arrangementen-page {

  #slider-range {
    background: #617365;
    width: 100%;
    @include rem(margin-bottom, 15px);
  }

  #slider-range .ui-slider-handle {
    background: $goud;
  }

  #slider-range .ui-slider-range {
    background: $groen-text;
  }
  #Form_FilterForm_VanafPrijs_Holder {
    .middleColumn {
      position: relative;
    }
    #amount {
      @include rem(padding-left, 30px);
    }
    .fa {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      color: $groen;
    }
  }


  .arrangementen-page--sidefilter {
    position: sticky;
    top: 0;

    .filterform--option {
      label.left {
        font-weight: 700;
        color: $groen-text;
      }
    }
  }

  .filter-form-title{
    .fa {
      @include rem(font-size, 21px);
    }
  }

  .card.arrangement-card {
    border-radius: 6px;
    overflow: hidden;

    .arrangement-card--title-link {
      color: $primaire-kleur;
    }

    .arrangement-filters {
      @include rem(font-size, 17px);
    }

    .card-body.arrangement-card-body {
      @include rem(padding-left, 45px);
      @include media-breakpoint-down(md){
        @include rem(padding-left, 15px);
      }
      .card-text{
        color: $grijs-text;
      }
      .arrangement-filters {
        color:$gray-700;

        .fa {
          color: $primaire-kleur;
        }
      }
    }
  }
  .card-img {
    border-radius: 0;
    height: 100%;
  }
}

.arrangementen-page {
  .itemfilter-form--trefwoord {
    color: $groen-text;
  }

  .filterform--icon {
    color: $goud;
  }

  .optionset,
  .checkboxset {
    color: $groen-text;
  }

  .filter-form-title {
    color: $white;
    .fa {
      color: $goud;
    }
  }

  .filterform--option {
    border-top: 1px solid lighten($groen, 5%);
    @include rem(padding-top, 15px);
  }

  .filter-form--options {
    label.left {
      color: $white;
      font-weight: 700;
      @include rem(font-size, 18px);
      letter-spacing: 0.3px;
    }
  }
}
