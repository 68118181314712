.topbar{
  background:$groen;
  .topbar-link{
    color:$groen-text;
    @include rem(font-size, 14px);
    @include rem(margin-right, 15px);
    i{
      color:$wit;
      @include rem(margin-right, 7px);
    }
    .badge{
      @include rem(width, 16px);
      background: $groen-text;
    }
  }
  .prijzen-btw {
    border: 0;
    color: $wit;
    background: transparent;
    border-radius: 3px;
    padding: 1px 7px;
    border: 2px solid transparentize($groen-text, 0.5);
    i{
      color: $wit;
    }
    &:active,
    &:focus{
      outline: 0;
      &:active,
      &:focus{
        outline: 0;
      }
    }
  }
}
