.element-news{
  position: relative;
  z-index: 5;
  .with-shadow{
    display: inline-block;
    transform: translateY(50%);
    z-index: 10;
    border-radius: 30px;
    &::after{
      content:url('../img/blad_siersel.svg');
      display: block;
      position: absolute;
      bottom: -20px;
      z-index: 0;
      opacity: 0.1;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .element-news-toptitle{
    @include rem(font-size, 26px);
    display: inline-block;
    margin: 0;
    z-index: 20;
    position: relative;
    background: $wit;
    border-radius: 30px;
    @include rem(padding, 18px 28px);
  }
  .default-title {
    @include rem(padding, 15px 30px);
    background: $wit;
    z-index: 5;
    position: relative;
    border-radius: 30px;
    &:after,
    &:before {
      display: none;
    }
  }
  .news-slider{
    .news-next{
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      z-index: 5;
      right: 50%;
      @include rem(padding, 12px 18px);
      @include media-breakpoint-between(md, xl) {
        right: 40%;
      }
      @include media-breakpoint-between(sm, md) {
        right: 20%;
      }
      @include media-breakpoint-between(xs, sm) {
        right: 5%;
      }
    }
  }
  .element-news-item{
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    &::before{
      content:'';
      background: linear-gradient(to right, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }
    .element-news-container{
      @include rem(padding, 150px);
      @include media-breakpoint-between(md, xl) {
        @include rem(padding, 75px 75px);
      }
      @include media-breakpoint-between(sm, md) {
        @include rem(padding, 75px 50px);
      }
      @include media-breakpoint-between(xs, sm) {
        @include rem(padding, 75px 50px);
      }
    }
    .element-news-title{
      display: inline-block;
      color:$wit;
      @include rem(font-size, 30px);
      @include rem(margin-bottom, 15px);
      &::after{
        content:'';
        display: block;
        height: 1px;
        width: 100%;
        background: linear-gradient(to right, rgba($goud, 10) 40%, rgba($goud, 0) 100%);
        @include rem(margin-top, 15px);
      }
    }
    .element-news-description{
      color:$wit;
      @include media-breakpoint-down(md) {
        text-shadow: 1px 1px 10px $black;
      }           
      @include media-breakpoint-between(xs, sm) {
        @include rem(padding-right, 40px);
      }
    }
    .element-news-content{
      position: relative;
    }
    .lezen{
      color:$wit;
      font-style: italic;
    }
    .btn-group{
      @include rem(margin-right, 10px);
      .btn{
        color:$primaire-kleur;
        margin-right:0;
        font-family: $font-family-body;
        text-transform: uppercase;
        font-weight: bold;
        @include rem(font-size, 14px);
        @include rem(line-height, 24px);
      }
      .btn-outline-light{
        color:$wit;
        font-family: $font-family-headings;
        &:hover{
          color:$primaire-kleur;
        }
      }
    }
    .btn{
      @include rem(margin-right, 10px);
    }
  }
}
