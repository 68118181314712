.meldingen{
  position: fixed;
  z-index: 50;
  bottom: 0px;
  width: 100%;
  .alert{
    opacity: 0;
  }
  .meldingen-container{
    @include rem(padding, 0px 150px);
    @include media-breakpoint-between(md, xl) {
      @include rem(padding, 0px 75px);
    }
    @include media-breakpoint-between(sm, md) {
      @include rem(padding, 0px 50px);
    }
    @include media-breakpoint-between(xs, sm) {
      @include rem(padding, 0px 50px);
    }
  }
  p{
    margin:0;
  }
}
