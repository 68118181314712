.footer{
  background-color:$secundaire-kleur;
  background-size: cover;
  background-position: top center;
  color:$wit;
  h1,h2,h3,h4,h5,h6{
    color:$wit;
  }
  ul{
    @include rem(margin-top, 25px);
    li{
      @include rem(line-height, 30px);
      list-style-type: none;
      a{
        color:$wit;
      }
    }
  }
  .fa{
    color:$goud;
    @include rem(margin-right, 15px);
  }

}

.footer-bottom-container{
  @include rem(padding,30px 50px);
  background: darken($groen, 5%);
  color:#7f8c81;
}

.footer-direct-to {
  .footer-direct-to--description {
    color: $groen-text;
  }
  
  .footer-direct-to--title {
    letter-spacing: 0.25px;
    color: $white;
    font-weight: 700;

    &::after{
      content:'';
      display: block;
      height: 1px;
      width: 100%;
      background: linear-gradient(to right, rgba($goud,0) 0%,rgba($goud,1) 25%,rgba($goud,1) 40%,rgba($goud,1) 60%,rgba($goud,1) 75%,rgba($goud,0) 100%);
      @include rem(margin,  10px auto);
      @include rem(max-width, 300px);
    }
  }
}
