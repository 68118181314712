.element-banner{
  position: relative;
  .banner--slider{
    .banner-next{
      z-index: 5;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      right: 50%;
      @include rem(padding, 12px 18px);
      @include media-breakpoint-between(md, xl) {
        right: 40%;
      }
      @include media-breakpoint-between(sm, md) {
        right: 20%;
      }
      @include media-breakpoint-between(xs, sm) {
        right: 5%;
      }
    }
  }
  .elemental-banner--item{
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    &.elemental-banner--height {
      @include rem(min-height, 420px);
      @include media-breakpoint-down(md){
        @include rem(min-height, 320px);
      }
    }
    &::before{
      content:'';
      background: linear-gradient(to right, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
    }
    .elemental-banner--title{
      display: inline-block;
      color:$wit;
      @include rem(font-size, 30px);
      @include rem(margin-bottom, 15px);
      &::after{
        content:'';
        display: block;
        height: 1px;
        width: 100%;
        background: linear-gradient(to right, rgba($goud, 10) 40%, rgba($goud, 0) 100%);
        @include rem(margin-top, 15px);
      }
    }
    .elemental-banner--description{
      color:$wit;
      @include media-breakpoint-down(md) {
        text-shadow: 1px 1px 10px $black;
      }
      @include media-breakpoint-between(xs, sm) {
        @include rem(padding-right, 40px);
      }
    }
    .elemental-banner--content{
      position: relative;
    }
  }
  .goto-nextblock{
    position: absolute;
    left: 50%;
    transform: translateX(50%);
    color:$primaire-kleur;
    z-index: 5;
    @include rem(height, 50px);
    @include rem(width, 50px);
    @include rem(padding, 10px);
    @include rem(bottom, 25px);
  }
}
