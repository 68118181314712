.cards-block{
  .cards-block-container{
    @include rem(padding, 75px 150px 0px 150px);
    @include media-breakpoint-between(md, xl) {
      @include rem(padding, 75px 75px 0px 75px);
    }
    @include media-breakpoint-between(sm, md) {
      @include rem(padding, 75px 50px 0px 50px);
    }
    @include media-breakpoint-between(xs, sm) {
      @include rem(padding, 75px 50px 0px 50px);
    }
  }
  .card{
    @include rem(margin-bottom, 30px);
  }
  .card-subtitle{
    @include rem(font-size, 18px);
    font-weight: 300;
  }
}
