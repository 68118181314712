.element-special{
  .element-special-content-left{
    @include rem(padding,75px 50px);
    background-color: $groen;
    background-repeat: no-repeat;
    background-position: top center;
    color:$wit;
    .element-special-smalltitle{
      color:$goud;
      text-transform: uppercase;
      @include rem(font-size, 14px);
    }
    .element-special-title{
      color:$wit;
      text-transform: uppercase;
      @include rem(margin,  30px 0px);
      @include rem(font-size, 36px);
      @include rem(letter-spacing, 1.5px);
      &::before{
        display: none;
      }
      &::after{
        content:'';
        display: inline-block;
        width:100%;
        height:1px;
        background: $goud;
        @include rem(margin-top,  30px);
        @include rem(max-width, 300px);
      }
    }
    .element-special-description{
      text-align: left;
      @include rem(padding,10px 30px);
      @include rem(line-height, 28px);
    }
  }
  .element-special-content-right{
    background-position: center center;
    background-size: cover;
    @include media-breakpoint-down(lg){
      @include rem(min-height, 240px);
    }
  }
}
