.arrangement {

  .arrangement--todo-title,
  .arrangement--interesse-title,
  .arrangement--locatie-title{
    color: $groen;
    font-weight: 700;
  }

  .combinatie-korting-title-right {
    @include rem(font-size, 18px);
    color:$secundaire-kleur;
    text-decoration: underline;
    padding: 0;
    border: 0;
    background: transparent;
    text-align: left;
    &:focus,
    &:active{
      outline: 0;
      &:focus,
      &:active{
        outline: 0;
      }
    }
  }

  .combinatie-korting-title {
    @include rem(font-size, 18px);
    color: $goud;
    padding: 0;
    border: 0;
    background: transparent;
    text-align: left;
    &:focus,
    &:active{
      outline: 0;
      &:focus,
      &:active{
        outline: 0;
      }
    }
  }

  #Form_InformatieAanvraagFormulier_CombinatieKortingArrangement_Holder {
    @include rem(padding, 15px);
    //border: 1px solid $fel-groen-alt;
    border-radius: 6px;
    background: rgba(27, 181, 24, 0.17);

    label{
      @include rem(font-size, 21px);
      color: $fel-groen-alt;
    }

    .combinatie-aanbeveling{
      @include rem(font-size, 16px);
      @include rem(margin-bottom, 10px);
      color: $fel-groen-alt;
      font-weight: 600;
    }
  }

  .review--item {
    .review--title {
      @include rem(font-size, 18px);
    }
    .review--score {
      color: $goud;
      @include rem(font-size, 21px);
      text-shadow: 0px 1px 1px #b59714;
      //text-shadow: 0px 1px 1px darken($goud, 12%);
    }
  }
  .share-title {
    @include rem(font-size, 18px);
  }
  .share-list {
    svg {
      @include rem(width, 42px);
      fill: $secundaire-kleur;
    }
  }
  .form-usps {
    color: $fel-groen-alt;
    @include rem(font-size,18px);
  }
  .informatie-aanvraag-form {
    .left-title {
      color: $secundaire-kleur;
      font-weight: 700;
    }
  }
  .arrangement--sidefilter {
      color: $groen-text;
      @include rem(padding, 10px);

      .arrangement--highlight-description,
      .arrangement--highlight-title {
        color: $groen;
      }

      .arrangement--title {
        color: $white;
        font-weight: 700;
        .fa {
          color: $goud;
        }
      }

      .filterform--option {
        border-top: 1px solid lighten($groen, 5%);
        @include rem(padding-top, 15px);
      }

      .arrangement-filters {
        @include rem(margin, 15px 0);

        .arrangement-filters--icon {
          color: $groen-text;
          @include rem(min-width, 34px);
          text-align: center;
        }

        .arrangement-filters--title {
          color: $white;
          @include rem(font-size, 18px);
          letter-spacing: 0.3px;
        }
      }
  }
}
