.product-page {

  .product-specificaties {
    @include rem(margin-top, 30px);
  }

  .product-extra-images {
    .element-blocks--image {
      position: relative;
      z-index: 30;

      .extra-image {
        display: block;
        overflow: hidden;
        border-radius: 6px;
        position: relative;

        &::before{
          content:'';
          background: linear-gradient(to right, rgba(0,0,0,0.25) 0%,rgba(0,0,0,0) 100%);
          width: 100%;
          height: 100%;
          position: absolute;
          left:0;
          top: 0;
          z-index: 5;
        }
      }

      .fa {
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 24px;
        transform: translate(-50%, -50%);
        color: $white;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
        z-index: 20;
      }
    }
  }

  .product-specificaties--title {
    @include rem(font-size, 24px);
  }

  .product-uitgelicht-item {
    display: block;
    @include rem(padding, 30px);
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    .inner-uitgelicht-item {
      position: relative;
      z-index: 5;
      color: $white;
    }

    .product-uitgelicht-title {
      color: $white;
      @include media-breakpoint-down(sm) {
        @include rem(font-size, 21px);
      }
    }
    &:hover {
      text-decoration: none;
    }
    &::before{
      content:'';
      background: linear-gradient(to right, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      left:0;
      top: 0;
    }
  }

  .addproductform {
    .numeric {
      label{
        float: left;
        @include rem(margin-top, 10px);
        @include rem(margin-right, 15px);
      }
    }

    input[name="Quantity"]{
      max-width: 80px;
    }
  }

  .simple-link {
    color: $primaire-kleur;
    background: transparent;
    border: 0;
    padding: 0;

    .gray-span{
      @include rem(font-size, 15px);
      color:$gray-800;
      text-decoration: underline;
    }

    &:hover {
      text-decoration: underline;
    }

    &:active,
    &:focus{
      outline: 0;

      &:active,
      &:focus{
        outline: 0;
      }
    }
  }

  .stock-available,
  .product--usps {
    i {
      color: $fel-groen;
    }
  }
}
