.cart-page{  
  #CartForm_CartForm{
    .ShippingTaxModifier,
    .ProductTaxModifier{
      display: none;
    }
    .btn-toolbar{
      float:left;
      #CartForm_CartForm_action_updatecart{
        margin: 15px;
        @extend .btn;
        @extend .btn-outline-secondary;
      }
    }
  }
}
