.element-blocks {
  .element-blocks--link {
    display: block;
    border-radius: 6px;
    transition: opacity .225s ease-in-out;
    opacity: 1;

    &:hover {
      text-decoration: none;
      opacity: 0.95;
    }
  }
  .element-blocks--item {
    @include rem(padding, 20px);
    @include media-breakpoint-up(fw) {
      @include rem(padding, 30px);
    }
    border-radius: 6px;
    position: relative;
    z-index: 5;
    background-color: $wit;
    border: 1px solid $gray-200;
  }
}
