/* Variables */

// Fonts
$font-family-body: "Roboto", Helvetica, Arial, sans-serif;
$font-family-headings: "Roboto Slab", serif;

$primaire-kleur: #af2226;
$secundaire-kleur: #334937;

$beige: #dcd9d5;
$wit: #ffffff;
$goud: #e8d373;
$groen: #324937;
$groen-alt: #829a4e;
$groen-text: #adb5af;
$fel-groen: #a3d733;
$fel-groen-alt: #1bb518;
$grijs-text: #484848;
$grijs-text-alt: #999999;
$grijs-dark: #444444;
$zwart: #000000;
$stepbg: #f7f7f7;
