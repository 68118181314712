.checkout-page {

  input#PaymentForm_ConfirmationForm_action_checkoutSubmit{
    @extend .btn-success;
  }

  .cart {
    &.table {
      .ShippingTaxModifier,
      .ProductTaxModifier{
        color: $gray-600;
        font-weight: 300;
        display: none;
        th {
          font-weight: 300;
        }
      }

    }
  }

  .checkout-content {
    h3 {
      color: $grijs-dark;
    }
  }
  .step {
    @include rem(width, 42px);
    @include rem(height, 42px);
    @include rem(line-height, 36px);
    @include rem(font-size, 21px);
    @include rem(margin-right, 7px);
    display: inline-block;
    text-align: center;
    border-radius: 100%;
    background: $stepbg;
    color: $secundaire-kleur;
    border: 2px solid $secundaire-kleur;
    &.highlighted {
      background: $primaire-kleur;
      color: $white;
      border: 2px solid $primaire-kleur;
    }
  }
  .checkout-page--membership {
    form {
      display: inline-block;

      input[type="submit"] {
        @include rem(margin, 5px);
      }
    }
  }
  .checkout-page--login {
    form#MemberLoginForm_LoginForm {
      //input[type="submit"] {
      //  @extend .btn-outline-secondary;
      //}
    }
  }
  .checkout-page--createaccount{
    form{
      #CheckoutForm_CreateAccountForm_Password_Holder {
        #Password_Password_Holder,
        #Password_ConfirmPassword_Holder{
          border: 0;
          margin-bottom: 0;
          height: inherit;
          padding: 0;
          @include rem(margin-bottom, 15px);
        }
      }
    }
  }
}
