.calltoaction-block{
  .calltoaction-block-container{
    @include rem(padding, 50px 150px);
    @include media-breakpoint-between(md, xl) {
      @include rem(padding, 50px 75px);
    }
    @include media-breakpoint-between(sm, md) {
      @include rem(padding, 50px 50px);
    }
    @include media-breakpoint-between(xs, sm) {
      @include rem(padding, 50px 50px);
    }
  }
  .calltoaction-block-title{
    margin-bottom: 0;
    span{
      display: block;
    }
    &::before{
      display: block;
      content:url('../img/grape_icon.svg');
      @include rem(max-width, 46px);
      @include rem(margin, 15px auto);
    }
    &::after{
      content:'';
      display: inline-block;
      width:100%;
      height:1px;
      background: linear-gradient(to right, rgba($groen-alt,0) 0%,rgba($groen-alt,1) 25%,rgba($groen-alt,1) 40%,rgba($groen-alt,1) 60%,rgba($groen-alt,1) 75%,rgba($groen-alt,0) 100%);
      @include rem(margin,  20px 0px);
      @include rem(max-width, 300px);
    }
  }
}
