.element-callout{
  .element-callout--item{
    background-color: $wit;
    position: relative;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    @include rem(padding, 35px);
    @include rem(min-height, 400px);
    &::before{
      content:'';

      background: linear-gradient(to right, transparentize($black, .35) 0%, transparentize($gray-900, 1) 100%);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    &::after{
      content:'';
      background: transparentize($gray-800, .75);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    .element-callout--content{
      position: relative;
      z-index: 20;
      .element-callout--description {
        @include rem(max-width, 600px);
        @include media-breakpoint-down(md) {
          text-shadow: 1px 1px 10px $black;
        }
      }
    }
    .element-callout--title{
      display: inline-block;
      color:$wit;
      @include rem(font-size, 30px);
      @include rem(margin-bottom, 15px);
      &::after{
        content:'';
        display: block;
        height: 1px;
        width: 100%;
        background: linear-gradient(to right, rgba($goud, 10) 40%, rgba($goud, 0) 100%);
        @include rem(margin-top, 15px);
      }
    }
    .element-callout--description{
      color:$wit;
    }
  }
}
