.category-page{
  background-position: top center;
  background-repeat: repeat-x;

  /*
  .breadcrumb {
    .breadcrumb-item {
      &.active,
      a {
        color: $wit;
      }
    }
  }
  */
}

.uigelichte-specs {
  @include rem(font-size, 15px);
}

.category-page--products-filter {
  .list-group-item {
    .fa {
      color: $primaire-kleur;
    }
  }
}

.category-page--header{
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include rem(padding, 60px 0);

  &::before{
    content:'';
    background: linear-gradient(to right, rgba(0,0,0,0.5) 0%,rgba(0,0,0,0) 100%);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }
  .category-page--title{
    display: inline-block;
    color:$wit;
    @include rem(font-size, 30px);
    @include rem(margin-bottom, 0);
    &::after{
      content:'';
      display: block;
      height: 1px;
      width: 100%;
      background: linear-gradient(to right, rgba($goud, 10) 40%, rgba($goud, 0) 100%);
      @include rem(margin-top, 15px);
    }
  }
  .category-header-content{
    a {
      text-decoration: underline;
      color: $wit;
    }
  }
  .category-page--content{
    color:$wit;
    @include rem(margin-top, 15px);
    @include rem(margin-bottom, 0);
  }
}

.element-products {
  .category-page--productlist {
    .element-blocks--link{
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

.category-page--productlist {
  .element-callout {
    .element-callout--item {
      border-radius: 3px;

      &::before{
        content:'';
        background: linear-gradient(to top, transparentize($black, .35) 0%, transparentize($gray-900, 1) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
      &::after{
        content:'';
        background: transparentize($gray-800, .92);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}

.form-filter-title {
  color: $secundaire-kleur;
}

.element-blocks--item {
  .left-title {
    @include rem(font-size, 28px);
  }
}

.filter-form--options{
  .optionset,
  .checkboxset{
    position: relative;
    &.field {
      box-shadow: 0 2px 3px rgba(51, 51, 51, 0.15);
    }
    .middleColumn{
      @extend .collapse;
      @include rem(padding, 10px 15px);
      background: $white;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 7px 15px;
      width: 100%;
      //position: absolute;
      z-index: 5;
      border-radius: 3px;
      @include media-breakpoint-down(md) {
        position: relative;
      }
      .optionset,
      .checkboxset{
        overflow: auto;
        @include rem(max-height, 230px);
        li{
          display: flex;
          margin: 4px 0;
          label{
            width: 100%;
            padding: 2px;
            margin: 2px 0 2px 0;
          }
          input{
            width: 21px;
            height: 21px;
            top: 6px;
          }
        }
      }
    }
    label.left {
      @include rem(line-height, 21px);
      @include rem(padding, 10px 15px);
      width: 100%;
      background: $white;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      border:3px solid $white;
      border-radius: 3px;
      color: $secundaire-kleur;
      font-weight: 700;
      &.has-options-checked{
        border: 3px solid $secundaire-kleur;
      }
      &:after{
        content: "\f106";
        display: inline-block;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        color:$secundaire-kleur;
        @include rem(font-size, 21px);
      }
      &.active{
        &:after{
          content: "\f107";
        }
      }
    }
  }
}
