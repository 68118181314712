/* Blogs */
.post-summary {
  float: left;
  width: 100%;
  @include rem(margin-bottom, 60px);
  h2 {
    display: inline;
    text-decoration: underline;
  }
}
.post-image {
  float: right;
  @include rem(margin-left, 40px);
  @include media-breakpoint-down(sm) {
    @include rem(margin, 0 0 30px 0);
    text-align: center;
    width: 100%;
  }
  img {
    @include media-breakpoint-up(sm) {
      @include rem(max-width, 180px);
    }
  }
}
.post-author {
  @include rem(margin-top, 10px);
  clear: left;
  color: #999;
  a {
    text-decoration: underline;
  }
}
.tag {
  @include rem(padding, 4px 6px 4px 6px);
  @include rem(border-radius, 3px);
  @include rem(font-size, 12px);
  background-color: #0275d8;
  color: #fff;
  text-decoration: none !important;
  &:hover,
  &:focus {
    background-color: #094c6b;
    color: #fff !important;
  }
}
.blog-footer {
  @include rem(padding, 15px 0 15px 0);
  float: left;
  width: 100%;
  border-top: 1px solid #ccc;
}
.blog-last-modified {
  float: left;
}
.blog-print {
  float: right;
  color: #767676;
  &:hover {
    color: #292b2c;
  }
}
#Form_CommentsForm {
  @include rem(margin-bottom, 20px);
}
.start-topic-header {
  @include rem(margin-top, 30px);
  display: block;
}
