.element-products{
  .element-blocks--link {
    @include rem(margin-top, 30px);
    @include rem(margin-bottom, 80px);
    .btn {
      @include rem(margin-top, 10px);
    }
  }
  .stock-available{
    i {
      color: $fel-groen;
    }
  }
  .product-block-title-wrap{
    .element-products-title{
      font-weight: bold;
      text-transform: uppercase;
      display:inline-block;
      @include rem(font-size, 28px);
      @include rem(letter-spacing, 1.5px);
      @include rem(margin-right, 15px);
    }
    .stock-available{
      color:$fel-groen;
      i{
        @include rem(margin-right,7px);
      }
    }
  }
  .element-products-content{
    @include rem(margin, 20px 0);
  }
  .price{
    color: $grijs-text;
    @include rem(font-size, 24px);
    @include rem(margin-bottom, 15px);
    @include rem(letter-spacing, 0.5px);
  }
  .element-products-price-old{
    @include rem(letter-spacing, 0.5px);
  }
  .element-products-price-discountpercentage{
    @include rem(letter-spacing, 0.5px);
  }
  .btn{
    @include rem(margin-right,10px);
  }
  .tags{
    text-transform: uppercase;
    @include rem(font-size,14px);
    color:$grijs-text-alt;
    .seperator{
      @include rem(margin, 0 10px);
    }
  }
  .product-prev{
    left:0;
  }
  .product-next{
    right: 0;
  }
  .product-prev,
  .product-next{
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 20;
    background-color: $grijs-dark;
    color: $wit;
    @include rem(padding, 12px 18px);
    &.slick-disabled{
      background: $wit;
      border-color: $grijs-dark;
      color: $grijs-dark;
    }
  }
  .products-slider{
    overflow: hidden;
    @include rem(padding, 0 50px);
    .slick-slide{
      outline:0;
      &:focus{
        outline:0;
      }
    }
  }
}
